import React from "react";

export const InsideStoryArea: React.FC = () => {
    return (
        <>
            <section className="about-area sec-mar">
                <div className="container">
                    <div className="out-story">
                        <div className="row">
                            <div className="col-lg-6 col-xl-6">
                                <div className="story-left">
                                    <div className="office-group-img">
                                        <img
                                            src={process.env.PUBLIC_URL + "/images/technology.jpg"}
                                            alt="images"
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6">
                                <div className="story-right">
                                    <div className="title black">
                                        <span>Nossa História</span>
                                        <h2 className="mb-15">
                                            Qualidade, Perfeccionismo e Segurança.
                                        </h2>
                                    </div>
                                    <p>
                                        A ARKAN nasceu de um entusiasmo por cibersegurança que promoveu capacitações técnicas
                                        e maturidade para a formação de uma equipe capaz de lidar com as adversidades na computação. A empresa
                                        evoluiu ao longo dos anos tornando-a capaz de lidar com todo o tipo de solução em software do mercado atual.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};