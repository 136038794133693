import React from "react";
import { Breadcrumb } from "../../common/Breadcrumb";
import { ContactUsArea } from "./ContactUsArea";
import { Header } from "../../common/Header";
import { Footer } from "../../common/Footer";

export const ContactPage: React.FC = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageName="Fale conosco" />
            <div className="contact-area sec-mar">
                <ContactUsArea />
            </div>
            <Footer />
        </>
    );
};