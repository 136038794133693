import React, { useState } from "react";
import { Link } from "react-router-dom";

export const Footer: React.FC = () => {
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-lg-3 col-xl-3">
                            <div className="footer-widget">
                                <div className="footer-logo">
                                    <Link onClick={scrollTop} to={"#"}>
                                        <img
                                            src={process.env.PUBLIC_URL + "/images/logo.png"}
                                            alt="img"
                                        />
                                    </Link>
                                </div>
                                <ul className="social-media-icons">
                                    <li>
                                        <a
                                            rel="noopener noreferrer"
                                            href="https://www.twitter.com/"
                                            target="_blank"
                                        >
                                            <i className="fab fa-twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href="https://www.instagram.com/"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-xl-3">
                            <div className="footer-widget">
                                <h4>Nossos Serviços</h4>
                                <ul className="footer-menu">
                                    <li>
                                        <Link
                                            onClick={scrollTop}
                                            to={`${process.env.PUBLIC_URL}/service-details`}
                                        >
                                            Web Development
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={scrollTop}
                                            to={`${process.env.PUBLIC_URL}/service-details`}
                                        >
                                            App Development
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={scrollTop}
                                            to={`${process.env.PUBLIC_URL}/service-details`}
                                        >
                                            System Development
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-xl-3">
                            <div className="footer-widget">
                                <h4>Companhia</h4>
                                <ul className="footer-menu">
                                    <li>
                                        <Link
                                            onClick={scrollTop}
                                            to={`${process.env.PUBLIC_URL}/about`}
                                        >
                                            Sobre
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={scrollTop}
                                            to={`${process.env.PUBLIC_URL}/service`}
                                        >
                                            Serviços
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-xl-3">
                            <div className="footer-widget">
                                <h4>Contato</h4>
                                <div className="number">
                                    <div className="num-icon">
                                        <i className="fas fa-phone-alt" />
                                    </div>
                                    <div className="phone">
                                        <a rel="noopener noreferrer" href="tel:05661111985">
                                            +55 (31) 98312-5110
                                        </a>
                                    </div>
                                </div>
                                <div className="office-mail">
                                    <div className="mail-icon">
                                        <i className="far fa-envelope" />
                                    </div>
                                    <div className="email">
                                        <a rel="noopener noreferrer" href="mailto:support@arkansoftware.com">
                                            support@arkansoftware.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                                <div className="copy-txt">
                                    <span>
                                        Copyright 2022 <b>ARKAN Software Intelligence</b>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="scroll-top opacity">
                <ScrollButton></ScrollButton>
            </div>
        </>
    );
};

const ScrollButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 800) {
            setVisible(true);
        } else if (scrolled <= 100) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    window.addEventListener("scroll", toggleVisible);

    return (
        <i
            className="bi bi-arrow-up"
            onClick={scrollToTop}
            style={{ display: visible ? "inline" : "none" }}
        >
            <span>top</span>
        </i>
    );
};
