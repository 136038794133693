import React from "react";
import { Breadcrumb } from "../../common/Breadcrumb";
import { Header } from "../../common/Header";
import { Footer } from "../../common/Footer";
import { InsideStoryArea } from "./InsideStoryArea";
import { AboutArea } from "../../pages/Home/AboutArea";

export const AboutPage: React.FC = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageName="Sobre" />
            <InsideStoryArea />
            <AboutArea />
            <Footer />
        </>
    );
};