import React from "react";
import { Link } from "react-router-dom";

export const WhatWeDoArea: React.FC = () => {
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <section className="service-area sec-pad">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-md-12 col-lg-4 col-xl-4">
                            <div className="title">
                                <span>O que fazemos</span>
                                <h2>Desenvolvemos soluções em software para todos os tipos de empresa.</h2>
                                <div className="cmn-btn">
                                    <Link
                                        onClick={scrollTop}
                                        to={`${process.env.PUBLIC_URL}/service`}
                                    >
                                        Todos os Serviços
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-8 col-xl-8">
                            <div className="row g-4">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <div className="single-service">
                                        <span className="count">01</span>
                                        <div className="service-icon">
                                            <i>
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/images/icons/service-icon-1.png"
                                                    }
                                                    alt="images"
                                                />
                                            </i>
                                        </div>
                                        <div className="service-content">
                                            <h4>Web Development</h4>
                                            <p>
                                                Desenvolvemos seu site com todos os últimos recursos tecnológicos e segurança.
                                            </p>
                                            <Link
                                                onClick={scrollTop}
                                                to={`${process.env.PUBLIC_URL}/service-details`}
                                            >
                                                Lêr Mais
                                                <i>
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/images/icons/arrow-circle.png"
                                                        }
                                                        alt="images"
                                                    />
                                                </i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <div className="single-service">
                                        <span className="count">02</span>
                                        <div className="service-icon">
                                            <i>
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/images/icons/service-icon-2.png"
                                                    }
                                                    alt="images"
                                                />
                                            </i>
                                        </div>
                                        <div className="service-content">
                                            <h4>App Development</h4>
                                            <p>
                                                Desenvolvimento de soluções móveis para sua empresa nas plataformas Android/iOS.
                                            </p>
                                            <Link
                                                onClick={scrollTop}
                                                to={`${process.env.PUBLIC_URL}/service-details`}
                                            >
                                                Lêr Mais
                                                <i>
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/images/icons/arrow-circle.png"
                                                        }
                                                        alt="images"
                                                    />
                                                </i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <div className="single-service">
                                        <span className="count">03</span>
                                        <div className="service-icon">
                                            <i>
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/images/icons/service-icon-3.png"
                                                    }
                                                    alt="images"
                                                />
                                            </i>
                                        </div>
                                        <div className="service-content">
                                            <h4>System Development</h4>
                                            <p>
                                                Desenvolvimento ágil de softwares customizados, sob medida para as necessidades da sua empresa.
                                            </p>
                                            <Link
                                                onClick={scrollTop}
                                                to={`${process.env.PUBLIC_URL}/service-details`}
                                            >
                                                Lêr Mais
                                                <i>
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/images/icons/arrow-circle.png"
                                                        }
                                                        alt="images"
                                                    />
                                                </i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};