import React from "react";
import { Link } from "react-router-dom";
import { Footer } from '../../common/Footer';
import { Header } from "../../common/Header";

export const ErrorPage: React.FC = () => {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header />
      <section className="notfound-error">
        <div className="container">
          <div className="error-wrapper">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-xl-6 or2">
                <div className="error-content">
                  <h2>404 Error</h2>
                  <h1>Página não encontrada</h1>
                  <p>
                    Parece que você seguiu um link quebrado ou inseriu uma URL que não existe neste site.
                  </p>
                  <div className="cmn-btn">
                    <Link onClick={scrollTop} to={"/"}>
                      Voltar para Home
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-6 or1">
                <div className="error-img">
                  <img
                    src={process.env.PUBLIC_URL + "/images/error.png"}
                    alt="error images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};