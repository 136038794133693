import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HomePage } from "../pages/Home/HomePage";
import { ContactPage } from "../pages/Contact/ContactPage";
import { AboutPage } from "../pages/About/AboutPage";
import { ServicesPage } from "../pages/Works/ServicesPage";
import { ServiceDetails } from "../pages/ServiceDetails/ServiceDetails";
import { ErrorPage } from "../pages/Error/ErrorPage";

export const ArkanRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/service" element={<ServicesPage />} />
      <Route path="/service-details" element={<ServiceDetails />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
