import React from "react";
import { Header } from '../../common/Header';
import { Footer } from '../../common/Footer';
import { Breadcrumb } from "../../common/Breadcrumb";
import { ServiceDetailsWrapper } from "./ServiceDetailsWrapper";

export const ServiceDetails: React.FC = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageName="Detalhes do Serviço" />
            <ServiceDetailsWrapper />
            <Footer />
        </>
    );
};
