import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    Autoplay,
    EffectFade,
    Navigation,
    Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

export const HowWeWorkArea: React.FC = () => {
    const WorkProgressSlide = {
        slidesPerView: 1,
        loop: true,
        speed: 2000,
        autoplay: {
            delay: 5000,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    };

    return (
        <>
            <section className="how-we-work sec-mar-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-xl-4">
                            <div className="title black">
                                <span>Como trabalhamos</span>
                                <h2>Nosso Processo de trabalho.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="swiper work-process">
                                <Swiper {...WorkProgressSlide} className="swiper-wrapper">
                                    <SwiperSlide className="swiper-slide">
                                        <div className="work-process-wrapper">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/work-process-slider-1.jpeg"
                                                }
                                                alt="images"
                                            />
                                            <div className="work-process-inner">
                                                <b>01</b>
                                                <h4>Levantamento &amp; análise de requisitos</h4>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide">
                                        <div className="work-process-wrapper">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/work-process-slider-2.jpg"
                                                }
                                                alt="images"
                                            />
                                            <div className="work-process-inner">
                                                <b>02</b>
                                                <h4>Fase de desenvolvimento</h4>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide">
                                        <div className="work-process-wrapper">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/work-process-slider-3.jpg"
                                                }
                                                alt="images"
                                            />
                                            <div className="work-process-inner">
                                                <b>03</b>
                                                <h4>Penetration Test</h4>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                                <div className="swiper-button-next" />
                                <div className="swiper-button-prev" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
