import React from "react";

export const ServiceDetailsWrapper: React.FC = () => {
    return (
        <>
            <section className="service-details sec-mar">
                <div className="container">

                    <div className="single-service-work-process">
                        <h3>Como trabalhamos em nossos serviços</h3>
                        <div className="single-step">
                            <div className="row">
                                <div className="col-md-8 col-xl-8 or2">
                                    <div className="step">
                                        <div className="step-count">
                                            <span>01</span>
                                        </div>
                                        <h4>Web Development</h4>
                                        <p>
                                            Construimos seu website de uma forma bem estruturada, eficiente 
                                            e utilizando as melhores práticas de desenvolvimento de software. Integramos dados de vários
                                            serviços de back-end e banco de dados com todos os padrões de segurança. Criamos e mantemos a documentação do software.
                                            Também nos responsabilizamos pela manutenção, expansão e escalabilidade.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xl-4 or1">
                                    <div className="step-img">
                                        <img
                                            src={process.env.PUBLIC_URL + "/images/step-1.jpg"}
                                            alt="images"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-step">
                            <div className="row">
                                <div className="col-md-4 col-xl-4">
                                    <div className="step-img">
                                        <img
                                            src={process.env.PUBLIC_URL + "/images/step-2.jpeg"}
                                            alt="images"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8 col-xl-8">
                                    <div className="step">
                                        <div className="step-count">
                                            <span>02</span>
                                        </div>
                                        <h4>App Development</h4>
                                        <p>
                                            Desenvolvemos aplicações móveis para sua empresa nas plataformas iOS e Android com os últimos recursos
                                            tecnólogicos da atualidade. Integramos com qualquer serviço e acompanhamos todo o ciclo de vida do 
                                            aplicativo (conceito, projeto, testes, lançamento e suporte).

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-step">
                            <div className="row">
                                <div className="col-md-8 col-xl-8 or2">
                                    <div className="step">
                                        <div className="step-count">
                                            <span>03</span>
                                        </div>
                                        <h4>System Development</h4>
                                        <p>
                                            Levantamos as necessidades de sua empresa conduzindo reuniões com a técnica de brainstorm 
                                            afim de mapear e identificar todos os requisitos necessários para o seu sistema. Estruturamos seu projeto e iniciamos o desenvolvimento, através
                                            de estratégias utilizando metodologia ágil entregaremos o produto no tempo ideal para sua empresa.

                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xl-4 or1">
                                    <div className="step-img">
                                        <img
                                            src={process.env.PUBLIC_URL + "/images/step-3.png"}
                                            alt="images"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
