/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useReducer } from "react";
import { Link, NavLink } from "react-router-dom";
//import AnimatedCursor from "react-animated-cursor";

interface Item {
    itemType: string,
}

interface SelectedItemState {
    selectedItem: Item
}

type Action = {
    type: string
}

const menuItems = [
    {
        itemType: 'homeOne',
    },
    {
        itemType: 'service',
    },
    {
        itemType: 'projects',
    },
];

const initialState = { selectedItem: menuItems[0] };

function reducer(state: SelectedItemState, action: Action): SelectedItemState {
    let newSelection = menuItems.find(item => item.itemType === action.type);
    if (newSelection !== undefined) {
        return { selectedItem: newSelection }
    } else {
        return state
    }
};

export const Header: React.FC = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const isSticky = () => {
        const header = document.querySelector(".position_top");
        const scrollTop = window.scrollY;

        if (header) {
            scrollTop >= 200
                ? header.classList.add("sticky")
                : header.classList.remove("sticky");
        }
    };

    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    });

    /*----for single sidebar event use one state-------*/
    const [sidebar, setSidebar] = useState<Boolean>(false);
    const showSidebar = () => {
        if (sidebar === false) {
            setSidebar(true);
        } else {
            setSidebar(false);
        }
    };

    //  scroll top event
    const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

    return (
        <>
            <header className="position_top">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col col-sm-3 col-md-3 col-lg-3 col-xl-2">
                            <div className="logo">
                                <Link onClick={scrollTop} to={"/"}>
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/logo.png"}
                                        alt=""
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="col col-sm-5 col-md-6 col-lg-6 col-xl-8 text-end">
                            <nav className={sidebar ? "main-nav slidenav" : "main-nav"}>
                                <div className="mobile-menu-logo">
                                    <Link onClick={scrollTop} to={"/"}>
                                        <img
                                            src={process.env.PUBLIC_URL + "/images/logo.png"}
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <ul>
                                    <li>
                                        <NavLink
                                            onClick={scrollTop}
                                            to={`/`}
                                        >
                                            Home
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            onClick={scrollTop}
                                            to={`${process.env.PUBLIC_URL}/about`}
                                        >
                                            Sobre
                                        </NavLink>
                                    </li>
                                    <li className="has-child" onClick={() => dispatch({ type: 'service' })}>
                                        <Link onClick={scrollTop} to={"#"}>
                                            Serviços
                                        </Link>
                                        <i className="bi bi-chevron-down" />
                                        <ul
                                            className={
                                                state.selectedItem.itemType === 'service'
                                                    ? "sub-menu d-block"
                                                    : "sub-menu d-none"
                                            }
                                        >
                                            <li>
                                                <NavLink
                                                    onClick={scrollTop}
                                                    to={`${process.env.PUBLIC_URL}/service`}
                                                >
                                                    Serviço
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    onClick={scrollTop}
                                                    to={`${process.env.PUBLIC_URL}/service-details`}
                                                >
                                                    Detalhes do Serviço
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <NavLink
                                            onClick={scrollTop}
                                            to={`${process.env.PUBLIC_URL}/contact`}
                                        >
                                            Contato
                                        </NavLink>
                                    </li>
                                </ul>
                                <div className="get-quate dn">
                                    <div className="cmn-btn">
                                        <Link
                                            onClick={scrollTop}
                                            to={`${process.env.PUBLIC_URL}/contact`}
                                        >
                                            Fale conosco
                                        </Link>
                                    </div>
                                </div>
                            </nav>
                            <div className="mobile-menu">
                                <div
                                    onClick={showSidebar}
                                    className={sidebar ? "cross-btn h-active" : "cross-btn"}
                                >
                                    <span className="cross-top" />
                                    <span className="cross-middle" />
                                    <span className="cross-bottom" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-2 text-end">
                            <div className="get-quate">
                                <div className="cmn-btn">
                                    <Link
                                        onClick={scrollTop}
                                        to={`${process.env.PUBLIC_URL}/contact`}
                                    >
                                        Fale conosco
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};