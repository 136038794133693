import React from "react";

export const AboutArea: React.FC = () => {
    return (
        <>
            <section className="about-area sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-xl-6">
                            <div className="about-left">
                                <div className="title black">
                                    <span>Sobre</span>
                                    <h2 className="mb-15">Nossa metodologia.</h2>
                                </div>
                                <p>
                                    Através de estratégias utilizando metodologia ágil juntamente com a capacitação técnica de nossos 
                                    profissionais conseguimos entregar soluções robustas e seguras em software no prazo ideal para sua empresa. 
                                </p>
                                <div className="our-mission">
                                    <div className="msn-icon">
                                        <i>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/mission-icon.png"
                                                }
                                                alt="images"
                                            />
                                        </i>
                                    </div>
                                    <div className="msn-content">
                                        <h5>Nossa Missão</h5>
                                        <p>
                                            Desenvolvimento de sistemas robustos com tecnologias avançadas e com todos os recursos de segurança da atualidade.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6">
                            <div className="about-right">
                                <div className="group-images">
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/agile-bottom.jpg"}
                                        alt="images"
                                    />
                                    <div className="about-top">
                                        <img
                                            src={process.env.PUBLIC_URL + "/images/cibersecurity-top.png"}
                                            alt="images"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};