import React from "react";
import { Header } from '../../common/Header';
import { Footer } from '../../common/Footer';
import { Breadcrumb } from "../../common/Breadcrumb";
import { WhatWeDoArea } from "./WhatWeDoArea";
import { HowWeWorkArea } from "./HowWeWorkArea";
import { LetsTalkArea } from "../../common/LetsTalkArea";

export const ServicesPage: React.FC = () => {
  return (
    <>
      <Header />
      <Breadcrumb pageName="Serviços" />
      <WhatWeDoArea />
      <HowWeWorkArea />
      <LetsTalkArea />
      <Footer />
    </>
  );
};