import React from "react";
import { Link } from "react-router-dom";

interface BreadcrumbProps {
    pageName: string,
};

export const Breadcrumb: React.FC<BreadcrumbProps> = ({pageName}: BreadcrumbProps) => {
    return (
        <>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <h1>{pageName}</h1>
                                <span>
                                    <Link to={"/"}>Home</Link>
                                    <i>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/icons/breadcrumb-arrow.svg"
                                            }
                                            alt="images"
                                        />
                                    </i>
                                    {pageName}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
