import React from 'react';
import { Header } from '../../common/Header';
import { Footer } from '../../common/Footer';
import { ServiceArea } from "./ServiceArea";
import { AboutArea } from "./AboutArea";
import { LetsTalkArea } from '../../common/LetsTalkArea';

export const HomePage: React.FC = () => {
  return (
    <>
      <Header />
      <ServiceArea />
      <AboutArea />
      <LetsTalkArea />
      <Footer />
    </>
  );
};
