import React from "react";

export const ContactUsArea: React.FC = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col col-xl-6">
                        <div className="title black">
                            <span>Entre em contato</span>
                            <h2>Você tem alguma dúvida? Precisa de Ajuda? Entre em contato conosco.</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-4 col-xl-4">
                        <div className="office-info">
                            <div className="icon">
                                <i className="bi bi-telephone" />
                            </div>
                            <h4>Phone</h4>
                            <a href="tel:+55(31)981446824">+55 (31) 98312-5110</a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-4">
                        <div className="office-info">
                            <div className="icon">
                                <i className="bi bi-envelope" />
                            </div>
                            <h4>Email</h4>
                            <a href="mailto:support@arkansoftware.com">support@arkansoftware.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
